import React from 'react';
import Helmet from 'react-helmet';

const AppAuthHelperRedirectPage = () => (
  <Helmet>
    <script type="text/javascript">
      {`/*
      This placeholder file handles the query string parameters provided by the AS
      when returning from the authorization endpoint. It uses them in the construction of a
      link to the default document for the same folder that this file is hosted within. The
      query string parameters are passed along within the hash fragment.
      */
      var queryString = window.location.search.substring(1); // includes '?'

      if (parent === window) { // if not in a frame
          var spaLocation = document.createElement("a");
          spaLocation.href = "${process.env.APP_URL}";
          spaLocation.hash = queryString;

          setTimeout(function() {
              window.location.assign(spaLocation.href);
          }, 0);
      } else {
          // we are within a frame - handle the query parameters directly
          window.location.hash = queryString;

          var appAuthHelperRedirectScript = document.createElement("script");
          appAuthHelperRedirectScript.src = "appAuthHelperFetchTokensBundle.js";
          appAuthHelperRedirectScript.async = false;
          document.getElementsByTagName("head")[0].appendChild(appAuthHelperRedirectScript);
      }`}
    </script>
  </Helmet>
);

export default AppAuthHelperRedirectPage;
